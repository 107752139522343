import $ from 'jquery';

var React = require('react');
const { useEffect, useState, useRef } = require('react');
import { useDispatch, useSelector } from 'react-redux';
const { useTranslation } = require('react-i18next');
var { EditPlannedEventModal } = require('EditPlannedEventModal');
var { EventsListPopup } = require('EventsListPopup');
const {
  getContent,
  iconSrcMap,
  getTotal,
  titlesMap,
  comparePlannerTypes,
  initPlannedForField,
  initUsedForField,
  initPhasesForField,
  wtrSubTypeToTitle,
} = require('./PlannerUtils');
import { waterTreatmentDisplayName } from '@managers/planningManager';
var { upper } = require('helpers');
var { get_field_history } = require('distributionActions');
require('figmaCSS');
require('./planner.css');
const { AddEventButton, Shadow } = require('./PlannerCommonUI');
const { SummaryCol } = require('./SummaryManager');
const { ContentRow } = require('./PlannerContentRow');

const DATES_LINE_HEIGHT = 62;
const LEFT_PADDING = 24;
const LEFT_COLUMN_WIDTH = 216;
const RIGHT_PADDING = 24;
const RIGHT_COLUMN_WIDTH = 168;
const ROW_HEIGHT = 32;
const TITLE_HEIGHT = 40;

function SingleEventContainer({
  isResponsive,
  type,
  title,
  units,
  content,
  lineIndex,
  summaryData,
  usedLine = false,
  topBorder = '',
}) {
  const { t } = useTranslation();

  if (usedLine) {
    topBorder = 'border-top border-grey91';
  }
  let isFirstLine = lineIndex === 0;
  let isSecondLine = lineIndex === 1;

  return (
    <div className='d-flex ml-0 mr-0 ' style={{ flex: 1, height: ROW_HEIGHT }}>
      <div
        className={[topBorder, 'align-items-center d-flex bg-white'].join(' ')}
        style={{ width: 72, zIndex: 1 }}
      >
        <span
          className='text-style-caps-11-bold text-grey71'
          style={{ letterSpacing: 0.11 }}
        >
          {t(title).toUpperCase()}
        </span>
      </div>

      <div className='d-flex flex-nowrap' style={{ flex: 1, zIndex: 0 }}>
        <Shadow
          direction={'left'}
          style={{ position: 'absolute' }}
          shouldHide={isFirstLine}
          rowHeight={ROW_HEIGHT}
        />
        <ContentRow
          isResponsive={isResponsive}
          usedLine={usedLine}
          content={content}
          isSecondLine={isSecondLine}
        />
      </div>
      <SummaryCol
        usedLine={usedLine}
        isFirstLine={isFirstLine}
        isSecondLine={isSecondLine}
        isResponsive={isResponsive}
        type={type}
        units={units}
        summaryData={summaryData}
        rightColWidth={RIGHT_COLUMN_WIDTH}
        rowHeight={ROW_HEIGHT}
      />
    </div>
  );
}

function DoubleEventContainer({
  isResponsive,
  title,
  units,
  content,
  type,
  shouldDrawTopBorder,
  summaryData,
  subType,
}) {
  let topBorder = shouldDrawTopBorder ? 'border-grey80 border-top' : '';
  const { t } = useTranslation();
  let tooltipID = `tooltip-${type}-${subType}`;
  let tooltipContainerID = `tooltip-container-${type}-${subType}`;
  let tooltipTitle = type === 'wtr' ? t(wtrSubTypeToTitle[subType]) : '';

  useEffect(() => {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({ offset: '0,6' });
    });
  }, []);

  return (
    <div className={['d-flex', topBorder].join(' ')} style={{ flex: 1 }}>
      <div
        id={tooltipContainerID}
        className='align-self-center'
        style={{ width: 104, padding: 8, paddingBottom: 0 }}
      >
        <div className='text-grey45 font-weight-semi-bold text-size-12 planner-item-title overflow-visible'>
          {title}
        </div>
        {units && (
          <div className='text-grey71 font-weight-semi-bold text-size-12'>
            ({units})
          </div>
        )}
        {type === 'wtr' && (
          <img
            src={require('images/icons/timer_icon.svg')}
            className='img-fluid'
            data-toggle='tooltip'
            id={tooltipID}
            data-container={`#${tooltipContainerID}`}
            data-placement='right'
            onMouseOver={() => {
              $(`#${tooltipID}`).tooltip('show');
            }}
            title={tooltipTitle}
          />
        )}
      </div>
      <div style={{ flex: 1 }}>
        <SingleEventContainer
          isResponsive={isResponsive}
          title='planning_planned'
          content={content.plan}
          type={type}
          units={units}
          summaryData={summaryData.totalPlanned}
        />
        <SingleEventContainer
          isResponsive={isResponsive}
          title='planning_used'
          usedLine={true}
          content={content.used}
          type={type}
          units={units}
          summaryData={summaryData.totalUsed}
        />
      </div>
    </div>
  );
}

function titlesNameToDisplay(type, subType) {
  const selectedFarmingUnit = useSelector(
    (state) => state?.distribution_data?.selected_entities?.farming_unit,
  );

  switch (type) {
    case 'irr':
      if (subType === 'h2o') return 'H₂O';
    case 'fert':
      return fertilizerDisplayName(subType);
    case 'wtr':
      switch (subType) {
        case 'hpo':
          return `${waterTreatmentDisplayName[subType]} ${selectedFarmingUnit?.wt?.material_concentrations?.hasOwnProperty('hpo') ? selectedFarmingUnit?.wt?.material_concentrations['hpo'] + `%` : ''}`;
        default:
          return waterTreatmentDisplayName[subType];
      }
    default:
      return upper(subType);
  }
}

function fertilizerDisplayName(subType) {
  if (subType === 'di ammonium phosphate') return 'DAP';
  if (subType === 'mono ammonium phosphate (solid)') return 'MAP (solid)';
  if (subType === 'mono ammonium phosphate (liquid)') return 'MAP (liquid)';
  if (subType === 'no3') return 'NO₃⁻';
  if (subType === 'nh4') return 'NH₄⁺';
  return upper(subType);
}

function EventContainer({
  isResponsive,
  units,
  type,
  content,
  lineIndex,
  summaryData,
}) {
  let subTypes = isComparableType(type) && content ? Object.keys(content) : [];
  let borderTop = lineIndex === 1 ? '' : 'border-top border-grey80';
  let partialBorderTop = lineIndex === 1 ? 'top-border-past' : '';

  return (
    <div className={['d-flex ', borderTop].join(' ')}>
      <div
        className={[
          'd-flex align-items-center justify-content-center right-border-icon',
          partialBorderTop,
        ].join(' ')}
        style={{ width: 40, paddingLeft: 12, paddingRight: 12 }}
      >
        <img src={iconSrcMap[type]} style={{ width: 16 }} />
      </div>
      <div style={{ flex: 1 }}>
        {subTypes && subTypes.length > 0 ? (
          subTypes.sort().map((subType, index) => {
            let title = titlesNameToDisplay(type, subType);
            let shouldDrawTopBorder = index > 0;
            units = content[subType].units || units;
            return (
              <div key={title}>
                <DoubleEventContainer
                  isResponsive={isResponsive}
                  title={title}
                  units={units}
                  content={content[subType]}
                  type={type}
                  shouldDrawTopBorder={shouldDrawTopBorder}
                  summaryData={summaryData[subType]}
                  subType={subType}
                />
              </div>
            );
          })
        ) : (
          <div className='d-flex' style={{ flex: 1 }}>
            <div
              className={[partialBorderTop, 'bg-white'].join(' ')}
              style={{ width: 104, zIndex: 1 }}
            />
            <SingleEventContainer
              isResponsive={isResponsive}
              title={titlesMap[type]}
              content={content}
              lineIndex={lineIndex}
              type={type}
              units={units}
              topBorder={partialBorderTop}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function isComparableType(type) {
  return ['irr', 'fert', 'wtr'].indexOf(type) > -1;
}

function FieldEventsBox({
  field,
  isResponsive,
  userData,
  displaySettings,
  printMode,
}) {
  const selectedFarmingUnit = useSelector((state) => {
    return state.distribution_data.selected_entities.farming_unit;
  });
  const selectedDistribution = useSelector(
    (state) => state.distribution_data.selected_entities.distribution,
  );
  const dispatch = useDispatch();
  const [firstExportInit, setFirstExportInit] = useState(false);
  const [fieldData, setFieldData] = useState({
    plannedItems: { irr: { h2o: {} } },
    seasonBoundary: {},
    usedItems: {},
    phases: {},
  });

  const addToFieldData = (newData) => {
    setFieldData((prev) => {
      prev = { ...prev, ...newData };
      return { ...prev };
    });
  };

  useEffect(() => {
    dispatch(
      get_field_history(
        selectedDistribution.id,
        selectedFarmingUnit.id,
        field.id,
      ),
    );
  }, []);

  useEffect(() => {
    initPhasesForField(field, addToFieldData);
  }, [field?.crop_data?.kc_table]);

  useEffect(() => {
    initPlannedForField(field, addToFieldData);
  }, [
    field.historical_data?.plan_irr,
    field.historical_data?.plan_fert,
    field.historical_data?.plan_wtr,
  ]);

  useEffect(() => {
    initUsedForField(field, addToFieldData);
  }, [
    field.historical_data?.wfr,
    field.historical_data?.fert,
    field.historical_data?.wtr,
  ]);

  function CreateEventContainer(type) {
    if (printMode && !firstExportInit) {
      setFirstExportInit(true);
      initPhasesForField(field, addToFieldData);
      initPlannedForField(field, addToFieldData);
      initUsedForField(field, addToFieldData);
    }
    if (type === 'irr') {
      return (
        <EventContainer
          isResponsive={isResponsive}
          type={type}
          units={userData.length}
          content={content[type]}
          summaryData={summaryData[type]}
        />
      );
    } else if (type === 'note') {
      return (
        <EventContainer
          isResponsive={isResponsive}
          content={content[type]}
          type={type}
          lineIndex={1}
        />
      );
    } else if (type === 'fert') {
      return (
        <EventContainer
          content={content[type]}
          isResponsive={isResponsive}
          type={type}
          summaryData={summaryData[type]}
        />
      );
    } else if (type === 'wtr') {
      return (
        <EventContainer
          isResponsive={isResponsive}
          content={content[type]}
          type={type}
          summaryData={summaryData[type]}
        />
      );
    }
    return (
      <EventContainer
        isResponsive={isResponsive}
        type={type}
        content={content[type]}
        lineIndex={0}
      />
    );
  }

  const {
    seasonBoundary = {},
    plannedItems = { irr: { h2o: {} } },
    usedItems = {},
    phases = {},
  } = fieldData;
  plannedItems['phases'] = phases;
  const { seasonStart, seasonEnd } = seasonBoundary;
  const { startDate, isWeekViewMode, startWithMonday } = displaySettings;
  let summaryData = {};

  const mandatoryTypes = ['phases', 'note', 'irr'];
  let actualPlannedTypes = Object.keys(plannedItems);
  let actualUsedTypes = Object.keys(usedItems);

  let plannerTypes = [
    ...new Set([...mandatoryTypes, ...actualPlannedTypes, ...actualUsedTypes]),
  ].sort(comparePlannerTypes);

  function processRawDataIntoVisibleContent() {
    let content = {};
    for (let type of plannerTypes) {
      content[type] = {};
      summaryData[type] = {};
      if (isComparableType(type)) {
        let allSubTypes = [];
        if (type in plannedItems) {
          allSubTypes = [...Object.keys(plannedItems[type])];
        }
        if (type in usedItems) {
          allSubTypes = [
            ...new Set([...allSubTypes, ...Object.keys(usedItems[type])]),
          ];
        }
        for (let subType of allSubTypes) {
          content[type][subType] = {};
          summaryData[type][subType] = {
            totalPlanned: getTotal(
              plannedItems[type] ? plannedItems[type][subType] : {},
              startDate,
              isWeekViewMode,
              startWithMonday,
              seasonStart,
            ),
            totalUsed: getTotal(
              usedItems[type] ? usedItems[type][subType] : {},
              startDate,
              isWeekViewMode,
              startWithMonday,
              seasonStart,
            ),
          };

          content[type][subType].plan = getContent(
            startDate,
            isWeekViewMode,
            startWithMonday,
            type,
            plannedItems[type] ? plannedItems[type][subType] : undefined,
            seasonStart,
            seasonEnd,
            field.id,
            subType,
          );
          content[type][subType].used = getContent(
            startDate,
            isWeekViewMode,
            startWithMonday,
            type,
            usedItems[type] ? usedItems[type][subType] : undefined,
            seasonStart,
            seasonEnd,
            field.id,
            subType,
          );
          content[type][subType].units =
            usedItems[type]?.[subType]?.units ??
            plannedItems[type]?.[subType]?.units;
        }
      } else {
        const extraData = plannedItems[type] || usedItems[type] || {};
        content[type] = getContent(
          startDate,
          isWeekViewMode,
          startWithMonday,
          type,
          extraData,
          seasonStart,
          seasonEnd,
          field.id,
        );
      }
    }
    return content;
  }

  const content = processRawDataIntoVisibleContent();

  return (
    <div
      className='border border-grey91 bg-white'
      style={{ overflow: 'hidden', flex: 1, borderRadius: 8, borderWidth: 3 }}
    >
      {/*Field Name*/}
      <div style={{ height: TITLE_HEIGHT }}>
        <div
          className='justify-content-between row position-sticky'
          style={{ padding: '8px 32px', fontSize: 15, height: TITLE_HEIGHT }}
        >
          <span className='text-grey45 font-weight-bold'>{field.name}</span>
          <AddEventButton fieldId={field.id} />
        </div>
      </div>

      {/*Content*/}
      <div>
        {Object.keys(content).map((key) => (
          <div key={key}>{CreateEventContainer(key)}</div>
        ))}
      </div>
    </div>
  );
}

export function PlanningPageContainer({
  allFields = [],
  isResponsive,
  displaySettings,
  dates,
  style,
  printMode,
}) {
  const myRef = useRef(null);
  const selectedField = useSelector(
    (state) => state.distribution_data.selected_entities.field,
  );
  const userData = useSelector((state) => state.authentication_data.user_data);
  useSelector(
    (state) => state.distribution_data.selected_entities.farming_unit,
  );
  useSelector((state) => state.distribution_data.field_load_history);
  useSelector((state) => state.distribution_data.farming_unit_load_history);

  useEffect(() => {
    myRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [myRef?.current]);

  let boxShadow = { boxShadow: '0px 4px 10px 0px rgba(145, 157, 180, 0.19)' };
  let leftBoxShadow = isResponsive ? boxShadow : {};

  return (
    <div>
      <EventsListPopup modalId={'eventsListModal'} />

      <div
        className='bg-white d-flex position-fixed w-100'
        style={{
          overflow: 'hidden',
          zIndex: 600,
          height: DATES_LINE_HEIGHT,
          ...style,
          ...boxShadow,
        }}
      >
        {/*Dates Line*/}
        <div
          className='d-flex'
          style={{
            paddingLeft: LEFT_PADDING + LEFT_COLUMN_WIDTH,
            flex: 1,
            height: 64,
            overflow: 'hidden',
          }}
        >
          <ContentRow
            isResponsive={isResponsive}
            content={dates}
            style={{ alignItems: 'baseline' }}
          />
        </div>
        <div
          style={{
            width: RIGHT_COLUMN_WIDTH + RIGHT_PADDING,
            position: 'sticky',
            right: 0,
            backgroundColor: 'white',
            ...leftBoxShadow,
          }}
        />
      </div>
      <div
        className='pb-4 position-relative'
        style={{
          paddingTop: 110,
          paddingLeft: LEFT_PADDING,
          paddingRight: RIGHT_PADDING,
          backgroundColor: printMode ? 'white' : '#eef1f6',
        }}
      >
        <div style={{ height: 25 }} />

        {/*FieldBoxArr*/}
        {allFields &&
          allFields?.map((field) => {
            if (!field) return null;
            let ref = field?.id === selectedField?.id ? myRef : null;
            return (
              <div
                className='d-flex'
                style={{ marginTop: 24 }}
                key={field?.id}
                ref={ref}
              >
                {!printMode && (
                  <EditPlannedEventModal
                    modalId={'eventModalId'}
                    allFields={allFields}
                  />
                )}
                <FieldEventsBox
                  field={field}
                  isResponsive={isResponsive}
                  userData={userData}
                  displaySettings={displaySettings}
                  printMode={printMode}
                />
              </div>
            );
          })}

        <div style={{ height: 56 }} />
      </div>
    </div>
  );
}
